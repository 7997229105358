const setupTags = (posts) => {
  const allTags = {};

  posts.forEach((post) => {
    post.frontmatter.category.forEach((tag) => {
      if (allTags[tag]) {
        allTags[tag] = allTags[tag] + 1;
      } else {
        allTags[tag] = 1;
      }
      /* console.log(tag) */
    });
  });
  const newTags = Object.entries(allTags).sort((a, b) => {
    const [firsttag] = a;
    const [secondTag] = b;
    return firsttag.localeCompare(secondTag);
    /* console.log(firsttag) */
  });
  /* console.log(newTags) */
  return newTags;
};

export default setupTags;
