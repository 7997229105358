import { graphql, Link } from "gatsby";
import React from "react";
import Layout from "../components/Layout";
import setupTags from "../Utils/setupTags";
import Title from "../components/Banner/Title";
import slugify from "slugify";
import SeO from "../components/SEO";

function tags({ data }) {
  const newTags = setupTags(data.allMdx.nodes);
  return (
    <>
      <Layout>
        <SeO title="Tags" />
        <main className="pageContainer">
          <Title title="Tags" />
          <section className="tags-page">
            {newTags.map((tag, index) => {
              const [text, value] = tag;
              const slug = slugify(text, { lower: true });
              return (
                <Link to={`/${slug}`} key={index} className="tagcategory">
                  <h4>{text}</h4>
                  <p>{value} posts</p>
                </Link>
              );
            })}
          </section>
        </main>
      </Layout>
    </>
  );
}

export default tags;

export const query = graphql`
  {
    allMdx {
      nodes {
        frontmatter {
          category
        }
      }
    }
  }
`;
