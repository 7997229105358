import React from "react";
const Title = ({ title }) => {
  return (
    <div className="titleDiv">
      <h4>{title}</h4>
      <div className="line"></div>
    </div>
  );
};

export default Title;
